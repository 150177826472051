import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';

//const api_url = "http://localhost/forecast/";
const api_url = "https://flukeforecastingservice.thefort.cloud/forecast/"

const axios = require('axios');
const axiosInstance = axios.create({
    baseURL: api_url,
    timeout: 480000
});


const ForecastTable = (props) => {

    const [forecast, setForecast] = useState();
    const [loaded, setLoaded] = useState(false);
    const [granularity, setGranularity] = useState("weekly");

    console.log(granularity);

    const MarginButton = (props) => {
        return (
            <Box m={1} display="inline">
                <Button onClick={() => {setGranularity(props.granularity)}}>{props.children}</Button>
            </Box>
        )
    }

    useEffect(() => {
        setLoaded(true);
        const query = `
            query{
                allWeeklyForecasts(level:"product_group") {
                    levelCode
                    forecasts {
                      startDate
                      endDate
                      prediction
                    }
                }
            }
        `
        axiosInstance.post(
            api_url,
            {query: query},
            {headers: {'Authorization': 'Token ' + sessionStorage.getItem('token')}}
        ).then(response => {

            if (response.data.errors && response.data.errors[0].message === 'Invalid token') {
                // Authorization token is invalid or expired
                props.onNotAuthenticated()
            }

            setForecast(response.data.data.allWeeklyForecasts);
        }).catch(error => {
            console.log(error);
        })
    }, [loaded, props]);

    if (forecast) {

        const columns: GridColDef[] = [{ field: 'levelCode', headerName: 'Product Group', width: 120 }].concat(
            forecast[0].forecasts.map((fd, i) => {
                return { field: 'c' + i, width: 100, renderHeader: () => {
                    return (
                        <Box m={0} p={0}>
                            <Typography variant="body2" fontWeight={500}>{fd.startDate}</Typography>
                            <Typography variant="body2" fontWeight={500}>{fd.endDate}</Typography>
                        </Box>
                    )
                }}
            })
        );

        const rows: GridRowsProp = forecast.map((f, i) => {
            return {id: i, levelCode: f.levelCode, ...Object.fromEntries(
                f.forecasts.map((value, i) => ['c'+i, value.prediction]))}
        });

        return (
            <Box>
                <Box mb={3}>
                    <MarginButton granularity="weekly">Weekly</MarginButton>
                    <MarginButton granularity="monthly">Monthly</MarginButton>
                    <MarginButton granularity="quarterly">Quarterly</MarginButton>
                    <MarginButton granularity="rolling30day">Rolling 30 Day</MarginButton>
                    <MarginButton granularity="rolling90day">Rolling 90 Day</MarginButton>
                </Box>
                <Box display="flex" height={800} width="100%">
                    <DataGrid rows={rows} columns={columns} />
                </Box>
            </Box>
        )
    } else {

        return(
            <Box width="60%" display="inline-block">
                <Typography variant="h3" gutterBottom>Loading...</Typography>
                <LinearProgress />
            </Box>
        )

    }

}

export default ForecastTable;
